<template>
  <router-view />
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useQuasar, useMeta } from 'quasar'
import { mergeMessages } from 'boot/i18n'
import { useConfiguration } from './composables/useConfiguration.pinia'

const meta = {
  link: {
    baseIcons: {
      rel: 'stylesheet',
      href: 'icons/app/icons.css'
    }
  }
}

export default defineComponent({
  name: 'App',
  setup () {
    const $q = useQuasar()
    const { brandConfig } = useConfiguration()
    const hasCustomIcons = brandConfig.value.get('icons.custom')
    const iconOverrides = brandConfig.value.get('icons.override')
    const overrideCss = brandConfig.value.get('icons.overrideCss')
    const overridePrefix = brandConfig.value.get('icons.prefix', '')

    if (hasCustomIcons) {
      meta.link.baseIcons.href = 'resources/css/icons.css'
    }

    if (iconOverrides && overrideCss) {
      meta.link.iconOverrides = {
        rel: 'stylesheet',
        href: overrideCss
      }
    }

    useMeta(meta)

    $q.iconMapFn = (iconName) => {
      // iconName is the content of QIcon "name" prop
      // your custom approach, the following
      // is just an example:
      if (iconName.startsWith('app:')) {
        // we strip the "app:" part
        const name = iconName.substring(4)
        let cls = 'w-icon-' + name

        if (iconOverrides?.[name]) {
          cls = overridePrefix + iconOverrides[name]
        }

        return { cls }
      }
    }

    onMounted(() => {
      const tenantI18n = brandConfig.value.i18n || {}
      mergeMessages(tenantI18n)
    })
  }
})
</script>
